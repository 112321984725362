import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Layout from "../../components/Institutional/Layout";
import Loading from "../../components/Loading";
import { postLead } from "../../services/leadService";
import LeadsForm from "../../components/Forms/Leads";
import Swal from "sweetalert2";

function Home() {
  const [lead, setLead] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = "We Care Sync - Simplifique a Gestão de Clínicas";
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLead((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    await postLead(lead)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: "Pronto!",
          text: "Seu cadastro foi realizado com sucesso.",
          icon: "success",
          confirmButtonText: "Ok",
        });
        event.target.reset();
        setLead({});
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Erro!",
          text:
            error.response.data.message ||
            error.message ||
            "Não foi possível enviar o cadastro.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-4KWR5LNY2P"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-4KWR5LNY2P');
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="section-height-100-center bg-sess-1">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <h1 className="font-black">
                  Gestão Simplificada de
                  <br />
                  Clínicas e Profissionais
                  <span className="color-primary">.</span>
                </h1>
                <h4 className="subtitle font-light mb-5">
                  Nossa plataforma permite que clínicas cadastrem seus
                  profissionais, pacientes e acompanhem a evolução dos
                  atendimentos de maneira centralizada. Com uma interface
                  intuitiva, você pode administrar agendas de forma eficiente,
                  garantindo o controle de atendimentos e a evolução clínica dos
                  pacientes em um único sistema.
                </h4>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="py-5">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <h2 className="font-black">
                  Acesso Unificado para Múltiplas Clínicas
                </h2>
                <h5>
                  Profissionais podem acessar suas áreas de trabalho em
                  diferentes clínicas usando o mesmo cadastro. Basta alternar
                  entre as clínicas, sem precisar de múltiplos logins. Tudo foi
                  pensado para facilitar o fluxo de trabalho, aumentando a
                  produtividade e a clareza na gestão.
                </h5>
              </Col>
              <Col xs={12} md={12} className="text-end mt-5">
                <h2 className="font-black">Fale Conosco!</h2>
                <h5>
                  Quer saber mais sobre como nossa solução pode otimizar a
                  gestão da sua clínica? Preencha o formulário abaixo e
                  entraremos em contato para agendar uma demonstração
                  personalizada.
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-sess-2 py-5">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <Card className="card-form form-lead">
                  <Row>
                    <Col xs={12} md={12}>
                      <h2 className="text-uppercase font-semi-bold mb-0">
                        Entre em contato
                      </h2>
                      <p className="font-light color-dark-gray mb-4">
                        Preencha o formulário abaixo e agende uma demonstração.
                      </p>
                    </Col>
                  </Row>
                  <Form className="w-100" onSubmit={handleSubmit}>
                    <LeadsForm
                      lead={lead}
                      handleInputChange={handleInputChange}
                      external={true}
                    />
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Loading show={loading} />
      </Layout>
    </>
  );
}

export default Home;
