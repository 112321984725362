import api from "../config/api.js";

const fetchData = async ({ startOfWeek, endOfWeek, patient, professional }) => {
  try {
    const params = new URLSearchParams();
    params.append("startOfWeek", startOfWeek);
    params.append("endOfWeek", endOfWeek);

    if (patient) {
      params.append("patient", patient);
    }

    if (professional) {
      params.append("professional", professional);
    }

    const response = await api.get(`/clinic-schedules?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const submitData = async (data) => {
  if (!data._id) {
    try {
      const response = await api.post("/clinic-schedules", data);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  } else {
    try {
      const response = await api.put(`/users-invitations/${data._id}`, data);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  }
};

const getSchudules = async () => {
  try {
    const response = await api.get("/clinic-schedules/select");
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const apiMethods = {
  fetchData,
  submitData,
  getSchudules,
};
export default apiMethods;
