import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
import RootElement from "./routes";
import "../src/assets/scss/styles.scss";
import "material-icons/iconfont/material-icons.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    {/* <React.StrictMode> */}
    <AuthProvider>
      <RootElement />
    </AuthProvider>
  </Router>
);
