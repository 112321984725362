import React, { Component } from "react";
import NavBar from "../Navbar";
import Footer from "../Footer";

export default class Layout extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <div className="w-100 mx-auto overflow-auto">{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}
