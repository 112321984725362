import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../../assets/img/logo-deitada.png";
import LogoWhite from "../../../assets/img/logo-deitada-2.png";
import { ButtonCallToACtion } from "../../Buttons";
import { useAuth } from "../../../context/authContext";

function NavBar() {
  const [scroll, setScroll] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  return (
    <Navbar
      collapseOnSelect
      className={`fixed-top navbar-insti navbar-expand-lg nav-menu ${
        scroll && "bg-color-dark-primary"
      }`}
      expand="lg"
    >
      <Container>
        <Navbar.Brand href="/" className="p-0">
          {scroll ? (
            <img src={LogoWhite} className="img-fluid logo" alt="" />
          ) : (
            <img src={Logo} className="img-fluid logo" alt="" />
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          className="justify-content-end"
          id="responsive-navbar-nav"
        >
          <Nav className="d-flex align-items-center">
            <Nav.Link href="/login" className="m-0" target="_blank">
              <ButtonCallToACtion
                type="button"
                btnText={
                  <>
                    <FontAwesomeIcon className="me-2" icon={faSignInAlt} />
                    <span>{user ? "Minha conta" : "Fazer login"}</span>
                  </>
                }
                onClick={() => (window.location.href = "/login")}
              />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
