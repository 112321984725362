import api from "../config/api.js";

export const fetchData = async ({
  page,
  sortBy,
  order,
  limit,
  search,
  status,
}) => {
  const response = await api.get(
    `/leads?paginate=true&page=${page}&sortBy=${sortBy}&order=${order}&limit=${limit}&search=${search}&status=${status}`
  );
  return response.data;
};

export const postLead = async (data) => {
  const response = await api.post("/lead", data);
  return response.data;
};

export const deleteData = async (id) => {
  const response = await api.delete(`/leads/${id}`);
  return response.data;
};
