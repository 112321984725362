import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";

export const LabelsDashboard = ({
  href = "users",
  icon = "group",
  title = "Usuários",
  description = "Total de usuários",
  count = "0",
}) => {
  const navigate = useNavigate();
  return (
    <Card
      className="labels-dashboard mb-3 mb-md-0"
      onClick={() => {
        navigate(`/${href}`);
      }}
    >
      <Card.Body>
        <Row>
          <Col xs={12} md={5}>
            <div className="icon-container">
              <div className="icon-badge">
                <span className="material-icons">{icon}</span>
              </div>
            </div>
          </Col>
          <Col xs={12} md={7}>
            <p className="text-uppercase fw-bold mb-0">{title}</p>
            <small>{description}</small>
            <h2 className="text-uppercase fw-bold mb-0 mt-1">
              <CountUp end={count} duration={1} />
            </h2>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
