import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { ButtonPrimary } from "../../Buttons";

const LeadsForm = ({ lead, handleInputChange, external = false }) => {
  return (
    <>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Nome
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={lead.name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              E-mail
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={lead.email}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Telefone
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="number"
              name="phone"
              value={lead.phone}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              CNPJ
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="cnpj"
              value={lead.cnpj}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={12}>
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">Mensagem</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              defaultValue={lead.description || ""}
              rows={4}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
      {external && (
        <Row>
          <Col xs={12} md={12} className="text-end">
            <ButtonPrimary type="submit" className="w-100" btnText="Enviar" />
          </Col>
        </Row>
      )}
    </>
  );
};

export default LeadsForm;
