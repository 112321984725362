import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import { LabelsDashboard } from "../../components/Cards/Labels";
import { useAuth } from "../../context/authContext";
import { getFirstName } from "../../config/utils";
import dashboardService from "../../services/dashboardService";

function DashboardAdmin() {
  const [data, setData] = useState({});
  const { user } = useAuth();

  useEffect(() => {
    document.title = "We Care Sync - Dashboard Admin";
    dashboardService.dashboardInfo().then((response) => {
      setData(response);
    });
  }, []);

  return (
    <>
      <Sidebar pageName="Dashboard Admin" pageUrl="/dashboard-admin">
        <Container fluid>
          <Row className="mt-3 mb-4">
            <Col xs={12} md={12}>
              <h5>
                Prazer em te ver novamente,{" "}
                <strong>{getFirstName(user.fullName)}</strong>!
              </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <LabelsDashboard
                title="Usuários"
                description="Total de usuários ativos"
                icon="group"
                count={data.totalUsers}
                href="users"
              />
            </Col>
            <Col xs={12} md={3}>
              <LabelsDashboard
                title="Clínicas"
                description="Total de clínicas ativas"
                icon="business"
                count={data.totalClinics}
                href="clinics"
              />
            </Col>
            <Col xs={12} md={3}>
              <LabelsDashboard
                title="Convites"
                description="Total de convites pendentes"
                icon="mail"
                count={data.totalInvitations}
                href="invitations"
              />
            </Col>
            <Col xs={12} md={3}>
              <LabelsDashboard
                title="Leads"
                description="Total de Leads"
                icon="person_add"
                count={data.totalLeads}
                href="leads"
              />
            </Col>
          </Row>
        </Container>
      </Sidebar>
    </>
  );
}

export default DashboardAdmin;
