import api from "../config/api.js";

const dashboardInfo = async () => {
  try {
    const response = await api.get(`/dashboard`);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const dashboardClinicInfo = async () => {
  try {
    const response = await api.get(`/dashboard-clinics`);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const dashboardAppointmentInfo = async (
  patient,
  professional,
  status,
  startDate,
  endDate
) => {
  try {
    const response = await api.get(
      `/appointments-clinics?patient=${patient}&professional=${professional}&status=${status}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const apiMethods = {
  dashboardInfo,
  dashboardClinicInfo,
  dashboardAppointmentInfo,
};
export default apiMethods;
