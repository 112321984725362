import React from "react";
import { Modal, Container, Row, Col, Form, Alert } from "react-bootstrap";
import { ButtonPrimary } from "../Buttons";
import { dateHourFormat } from "../../config/utils";
import { useAuth } from "../../context/authContext";

function CustomModal({
  show,
  onHide,
  title,
  children,
  submitLabel = "Salvar",
  onSubmit,
  errorMessage,
  disabled,
  type = "form",
  size = "lg",
  data = {},
}) {
  const { user } = useAuth();

  return (
    <Modal show={show} onHide={onHide} size={size}>
      <Modal.Header
        className="border-0 p-4 d-flex align-items-center bg-color-light-gray"
        closeButton
      >
        <Modal.Title className="d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-start justify-content-center flex-column ml-3">
            <h5 className="mb-0 fw-bold color-dark-gray">{title}</h5>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {type === "form" ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              {children}
              {errorMessage !== "" && (
                <Row>
                  <Col xs={12} className="d-flex justify-content-center">
                    <Alert
                      variant="danger"
                      className="mb-0 text-center small w-auto"
                    >
                      {errorMessage}
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <ButtonPrimary
                    type="submit"
                    btnText={submitLabel}
                    disabled={disabled}
                    className="mt-2"
                  />
                </Col>
              </Row>
            </Form>
          ) : (
            <>{children}</>
          )}
        </Container>
      </Modal.Body>
      {type !== "form" && user.clinicRole !== "user" && (
        <Modal.Footer>
          <Container fluid>
            <Row className="mb-2">
              <Col xs={12} md={6}>
                <small className="text-muted">
                  Criado em: <strong>{dateHourFormat(data.createdAt)}</strong>.
                </small>
              </Col>
              <Col xs={12} md={6} className="text-start text-md-end">
                <small className="text-muted">
                  Atualizado em:{" "}
                  <strong>{dateHourFormat(data.updatedAt)}</strong>.
                </small>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      )}
    </Modal>
  );
}

function CustomModalClinics({
  show,
  onHide,
  title = "Selecionar Clínica",
  children,
}) {
  return (
    <Modal show={show} onHide={onHide} size="md">
      <Modal.Header
        className="border-0 p-4 d-flex align-items-center bg-color-light-gray"
        closeButton
      >
        <Modal.Title className="d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-start justify-content-center flex-column ml-3">
            <h5 className="mb-0 fw-bold color-dark-gray">{title}</h5>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              {children}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export { CustomModal, CustomModalClinics };
