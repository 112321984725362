import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import {
  CardList,
  CardListHeader,
  CardListHeaderItem,
  CardListBody,
  CardListBodyItem,
  CardListBodyButton,
} from "../../../../components/CardList";
import FilterBar from "../../../../components/FiltersBar";
import { CustomModal } from "../../../../components/Modal";
import AppointmentsSingleForm from "../../../../components/Forms/Dashboard/_clinics/Appointments/Single";
import appointmentService from "../../../../services/clinicAppointmentService";
import patientService from "../../../../services/clinicPatientService";
import {
  dateFormat,
  translateAppointmentStatus,
} from "../../../../config/utils";
import Swal from "sweetalert2";

function MyAppointments() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [appointment, setAppointment] = useState([]);
  const [patients, setPatients] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    sort: { sortBy: "createdAt", order: "desc" },
    limit: 20,
    search: "",
    patient: "all",
    status: "all",
    startDate: "",
    endDate: "",
  });
  const [showModalView, setShowModalView] = useState(false);

  const filterConfig = [
    {
      type: "select",
      label: "Paciente",
      name: "patient",
      size: 2,
      options: [
        { label: "Todos", value: "all" },
        ...patients.map((patient) => ({
          label: `${patient.fullName}`,
          value: patient._id,
        })),
      ],
    },
    {
      type: "select",
      label: "Filtrar por status",
      name: "status",
      options: [
        { label: "Todos", value: "all" },
        { label: "Confirmados", value: "confirmed" },
        { label: "Atendidos", value: "attended" },
      ],
      size: 2,
    },
    {
      type: "select",
      label: "# itens",
      name: "limit",
      options: [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      size: 1,
    },
    {
      type: "date",
      label: "Data de Início",
      name: "startDate",
      size: 3,
    },
    {
      type: "date",
      label: "Data de Fim",
      name: "endDate",
      size: 3,
    },
  ];

  const refreshItems = async () => {
    setShowModalView(false);
    setAppointment({});
    setErrorMessage("");
    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { page, sort, limit, search, patient, status, startDate, endDate } =
        filters;
      const params = {
        page,
        sortBy: sort.sortBy,
        order: sort.order,
        limit,
        search,
        patient,
        status,
        startDate: startDate || null,
        endDate: endDate || null,
      };
      const response = await appointmentService.fetchMyData(params);
      const pats = await patientService.getPatients();
      setData(response.docs);
      setDataInfo({ ...response });
      setPatients(pats);
    } catch (error) {
      console.log(error);
      Swal.fire("Erro!", error.message, "error");
    }
    refreshItems();
  }, [filters]);

  useEffect(() => {
    document.title = "We Care Sync - Meus Atendimentos";
    fetchData();
  }, [fetchData]);

  const prevPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
    }));
  };

  const nextPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page:
        prevFilters.page < dataInfo.totalPages
          ? prevFilters.page + 1
          : prevFilters.page,
    }));
  };

  const handleInputSearch = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAppointment((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const submitData = async () => {
    setLoading(true);
    try {
      await appointmentService.attendAppointment(appointment);
      await fetchData();
      setLoading(false);
      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: `Atendimento salvo com sucesso!`,
        });
      }, 300);
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `Erro ao salvar`,
        });
      }, 300);
      setErrorMessage(error.message);
    }
  };
  return (
    <>
      <Sidebar pageName="Meus Atendimentos" pageUrl="/meus-atendimentos">
        <Container fluid>
          <FilterBar
            config={filterConfig}
            filters={filters}
            onFilterChange={handleInputSearch}
          />
          <Row>
            <Col>
              <CardList
                page={parseInt(dataInfo.page)}
                data={data}
                pages={dataInfo.totalPages}
                callbackNext={nextPage}
                callbackPrev={prevPage}
              >
                <CardListHeader className="bg-color-light-gray">
                  <Row>
                    <CardListHeaderItem xs={12} lg={2}>
                      Paciente
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={4}>
                      Informações do Paciente
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={2}>
                      Agenda
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={2}>
                      Status
                    </CardListHeaderItem>
                    <CardListHeaderItem
                      xs={12}
                      lg={2}
                      className="justify-content-center"
                    >
                      Gerenciar
                    </CardListHeaderItem>
                  </Row>
                </CardListHeader>

                {data.map((data, index) => {
                  const appointmentDate = new Date(data.date).getTime();
                  const currentDate = new Date().getTime();

                  return (
                    <CardListBody key={index}>
                      <Row>
                        <CardListBodyItem
                          xs={12}
                          lg={2}
                          className="d-inline-flex align-items-center text-muted small"
                          title={"Paciente:"}
                          value={data.patient?.fullName}
                        />
                        <CardListBodyItem
                          xs={12}
                          lg={4}
                          className="d-inline-flex align-items-center text-muted small"
                          title="Informações adicionais:"
                          value={data.patient?.additionalInfo}
                          isHtml={true}
                          maxLength={100}
                        />
                        <CardListBodyItem
                          xs={12}
                          lg={2}
                          className="d-inline-flex align-items-center text-muted small"
                          title={"Agenda:"}
                          value={`${dateFormat(data.date)}, de ${
                            data.schedule?.startTime
                          } até ${data.schedule?.endTime}`}
                        />
                        <CardListBodyItem
                          xs={12}
                          lg={2}
                          className={`d-inline-flex align-items-center small fw-bold text-${translateAppointmentStatus(
                            "color",
                            data.status
                          )}`}
                          title={"Status:"}
                          value={`${translateAppointmentStatus(
                            "label",
                            data.status
                          )}`}
                        />
                        {appointmentDate <= currentDate && (
                          <CardListBodyButton
                            xs={12}
                            lg={2}
                            className={`d-inline-flex justify-content-center align-items-center ${
                              data.status === "attended" && "appointment-btn"
                            }`}
                            callToAction={() => {
                              setAppointment(data);
                              setShowModalView(true);
                            }}
                            title={
                              data.status === "confirmed"
                                ? "Atender"
                                : "Alterar"
                            }
                          />
                        )}
                      </Row>
                    </CardListBody>
                  );
                })}
                {data.length === 0 ? (
                  <>
                    <CardListBody>
                      <Col
                        xs={12}
                        className="d-inline-flex align-items-center text-muted small justify-content-center"
                      >
                        Nenhum item encontrado.
                      </Col>
                    </CardListBody>
                  </>
                ) : (
                  <></>
                )}
              </CardList>
            </Col>
          </Row>
        </Container>
        <CustomModal
          show={showModalView}
          onHide={() => {
            refreshItems();
          }}
          title={"Editar Atendimento"}
          submitLabel={"Salvar Alterações"}
          onSubmit={submitData}
          errorMessage={errorMessage}
          type="form"
        >
          <AppointmentsSingleForm
            appointment={appointment}
            handleInputChange={handleInputChange}
          />
        </CustomModal>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default MyAppointments;
