import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import {
  CardList,
  CardListHeader,
  CardListHeaderItem,
  CardListBody,
  CardListBodyItem,
  CardListBodyItemOptions,
} from "../../../../components/CardList";
import FilterBar from "../../../../components/FiltersBar";
import { CustomModal } from "../../../../components/Modal";
import AppointmentsForm from "../../../../components/Forms/Dashboard/_clinics/Appointments";
import appointmentService from "../../../../services/clinicAppointmentService";
import roleService from "../../../../services/clinicRoleService";
import patientService from "../../../../services/clinicPatientService";
import scheduleService from "../../../../services/clinicScheduleService";
import {
  dateFormat,
  translateAppointmentStatus,
} from "../../../../config/utils";
import Swal from "sweetalert2";
import { useAuth } from "../../../../context/authContext";

function Appointments() {
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [appointment, setAppointment] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [patients, setPatients] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const { user } = useAuth();
  const [filters, setFilters] = useState({
    page: 1,
    sort: { sortBy: "createdAt", order: "desc" },
    limit: 20,
    search: "",
    patient: "all",
    professional: "all",
    status: "all",
    startDate: "",
    endDate: "",
  });
  const [showModalEditAdd, setshowModalEditAdd] = useState(false);
  const [showModalView, setShowModalView] = useState(false);

  const filterConfig = [
    {
      type: "select",
      label: "Paciente",
      name: "patient",
      size: 2,
      options: [
        { label: "Todos", value: "all" },
        ...patients.map((patient) => ({
          label: `${patient.fullName}`,
          value: patient._id,
        })),
      ],
    },
    {
      type: "select",
      label: "Profissional",
      name: "professional",
      size: 2,
      options: [
        { label: "Todos", value: "all" },
        ...professionals.map((professional) => ({
          label: `${professional.user.fullName}`,
          value: professional._id,
        })),
      ],
    },
    {
      type: "select",
      label: "Filtrar por status",
      name: "status",
      options: [
        { label: "Todos", value: "all" },
        { label: "Pendentes", value: "pending" },
        { label: "Confirmado", value: "confirmed" },
        { label: "Atendido", value: "attended" },
        { label: "Cancelado sem Justificativa", value: "rejected" },
        { label: "Cancelado com Justificativa", value: "canceled" },
        { label: "Rejeitados", value: "rejected" },
      ],
      size: 2,
    },
    {
      type: "select",
      label: "# itens",
      name: "limit",
      options: [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      size: 1,
    },
    {
      type: "date",
      label: "Data de Início",
      name: "startDate",
      size: 2,
    },
    {
      type: "date",
      label: "Data de Fim",
      name: "endDate",
      size: 2,
    },
  ];

  const refreshItems = async () => {
    setshowModalEditAdd(false);
    setShowModalView(false);
    setAppointment({});
    setErrorMessage("");
    setLoading(false);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const {
        page,
        sort,
        limit,
        search,
        patient,
        professional,
        status,
        startDate,
        endDate,
      } = filters;
      const params = {
        page,
        sortBy: sort.sortBy,
        order: sort.order,
        limit,
        search,
        patient,
        professional,
        status,
        startDate: startDate || null,
        endDate: endDate || null,
      };
      const response = await appointmentService.fetchData(params);
      if (firstLoad && user.clinicRole !== "user") {
        await Promise.all([
          roleService.getProfessionals(),
          patientService.getPatients(),
          scheduleService.getSchudules(),
        ]).then((values) => {
          setProfessionals(values[0]);
          setPatients(values[1]);
          setSchedules(values[2]);
        });
      }
      setData(response.docs);
      setDataInfo({ ...response });
      setFirstLoad(false);
    } catch (error) {
      console.log(error);
      Swal.fire("Erro!", error.message, "error");
    }
    refreshItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    document.title = "We Care Sync - Atendimentos";
    fetchData();
  }, [fetchData]);

  const prevPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
    }));
  };

  const nextPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page:
        prevFilters.page < dataInfo.totalPages
          ? prevFilters.page + 1
          : prevFilters.page,
    }));
  };

  const handleInputSearch = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAppointment((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const confirmAttendance = async (data) => {
    setLoading(true);
    if (data.status !== "pending") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Erro!",
        text: `Não é possível confirmar o atendimento.`,
      });
    }

    data.status = "confirmed";
    try {
      await appointmentService.submitData(data);
      await fetchData();
      setLoading(false);
      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: `Atendimento confirmado com sucesso!`,
        });
      }, 300);
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `Erro ao confirmar`,
        });
      }, 300);
      setErrorMessage(error.message);
    }
  };

  const submitData = async () => {
    setLoading(true);
    try {
      await appointmentService.submitData(appointment);
      await fetchData();
      setLoading(false);
      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: `Atendimento salvo com sucesso!`,
        });
      }, 300);
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `Erro ao salvar`,
        });
      }, 300);
      setErrorMessage(error.message);
    }
  };
  return (
    <>
      <Sidebar pageName="Atendimentos" pageUrl="/atendimentos">
        <Container fluid>
          <FilterBar
            config={filterConfig}
            filters={filters}
            onFilterChange={handleInputSearch}
            onAddClick={() => setshowModalEditAdd(true)}
            onAddText="Adicionar Atendimento"
            adminOnly={true}
          />
          <Row>
            <Col>
              <CardList
                page={parseInt(dataInfo.page)}
                data={data}
                pages={dataInfo.totalPages}
                callbackNext={nextPage}
                callbackPrev={prevPage}
              >
                <CardListHeader className="bg-color-light-gray">
                  <Row>
                    <CardListHeaderItem xs={12} lg={3}>
                      Paciente
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={3}>
                      Profissional
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={3}>
                      Agenda
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={2}>
                      Status
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={1} className="text-center">
                      Gerenciar
                    </CardListHeaderItem>
                  </Row>
                </CardListHeader>

                {data.map((data, index) => (
                  <CardListBody key={index}>
                    <Row>
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Paciente:"}
                        value={data.patient?.fullName}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Profissional:"}
                        value={`${data.user?.user?.fullName}, ${data.user?.profession}`}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Agenda:"}
                        value={`${dateFormat(data.date)}, de ${
                          data.schedule?.startTime
                        } até ${data.schedule?.endTime}`}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={2}
                        className={`d-inline-flex align-items-center small fw-bold text-${translateAppointmentStatus(
                          "color",
                          data.status
                        )}`}
                        title={"Status:"}
                        value={`${translateAppointmentStatus(
                          "label",
                          data.status
                        )}`}
                      />
                      <CardListBodyItemOptions
                        xs={12}
                        lg={1}
                        className="d-inline-flex align-items-center justify-content-center"
                      >
                        {user.clinicRole !== "user" &&
                          data.status !== "attended" && (
                            <>
                              <Dropdown.Item
                                className="text-primary font-semi-bold text-center"
                                onClick={() => {
                                  setAppointment(data);
                                  setshowModalEditAdd(true);
                                }}
                              >
                                Editar informações
                              </Dropdown.Item>
                              {data.status === "pending" && (
                                <Dropdown.Item
                                  className="text-success font-semi-bold text-center"
                                  onClick={() => confirmAttendance(data)}
                                >
                                  Confirmar Atendimento
                                </Dropdown.Item>
                              )}
                            </>
                          )}
                        <Dropdown.Item
                          className="color-dark-tertiary font-semi-bold text-center"
                          onClick={() => {
                            setAppointment(data);
                            setShowModalView(true);
                          }}
                        >
                          Ver Informações
                        </Dropdown.Item>
                      </CardListBodyItemOptions>
                    </Row>
                  </CardListBody>
                ))}
                {data.length === 0 ? (
                  <>
                    <CardListBody>
                      <Col
                        xs={12}
                        className="d-inline-flex align-items-center text-muted small justify-content-center"
                      >
                        Nenhum item encontrado.
                      </Col>
                    </CardListBody>
                  </>
                ) : (
                  <></>
                )}
              </CardList>
            </Col>
          </Row>
        </Container>
        <CustomModal
          show={showModalEditAdd}
          onHide={() => {
            refreshItems();
          }}
          title={
            appointment._id ? "Editar Atendimento" : "Adicionar Atendimento"
          }
          submitLabel={
            appointment._id ? "Salvar Alterações" : "Adicionar Atendimento"
          }
          onSubmit={submitData}
          errorMessage={errorMessage}
          type="form"
        >
          <AppointmentsForm
            professionals={professionals}
            patients={patients}
            schedules={schedules}
            appointment={appointment}
            handleInputChange={handleInputChange}
          />
        </CustomModal>
        <CustomModal
          show={showModalView}
          onHide={() => {
            refreshItems();
          }}
          title={"Informações do Atendimento"}
          submitLabel={"Fechar"}
          type="view"
          data={appointment}
        >
          <Row>
            <Col xs={12} sm={3} className="mb-3">
              <h6 className="fw-bold">Paciente</h6>
              <p className="text-muted">{appointment.patient?.fullName}</p>
            </Col>
            <Col xs={12} sm={3} className="mb-3">
              <h6 className="fw-bold">Profissional</h6>
              <p className="text-muted">
                {appointment.user?.user?.fullName},{" "}
                {appointment.user?.profession}
              </p>
            </Col>
            <Col xs={12} sm={3} className="mb-3">
              <h6 className="fw-bold">Agenda</h6>
              <p className="text-muted">
                {dateFormat(appointment.date)}, de{" "}
                {appointment.schedule?.startTime} até{" "}
                {appointment.schedule?.endTime}
              </p>
            </Col>
            <Col xs={12} sm={3} className="mb-3">
              <h6 className="fw-bold">Status</h6>
              <p
                className={`fw-bold text-${translateAppointmentStatus(
                  "color",
                  appointment.status
                )}`}
              >
                {translateAppointmentStatus("label", appointment.status)}
              </p>
            </Col>
            {(appointment.status === "attended" ||
              appointment.status === "canceled") && (
              <Col xs={12} sm={12} className="mb-3">
                <h6 className="fw-bold">{`Descrição do ${
                  appointment.status === "attended"
                    ? "Atendimento"
                    : "Cancelamento"
                }`}</h6>
                <p className="text-muted">{appointment.notes}</p>
              </Col>
            )}
          </Row>
        </CustomModal>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Appointments;
