import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../../assets/img/logo-em-pe-white.png";

function Footer() {
  return (
    <>
      <div className="footer bg-color-dark-tertiary pt-5 pb-3">
        <Container>
          <Row className="text-center mb-4">
            <Col xs={12}>
              <img src={Logo} className="img-fluid logo" alt="" />
            </Col>
          </Row>
          <hr className="color-white" />
          <Row className="align-items-center text-center-mobile">
            <Col xs={12} md={10}>
              <span className="color-gray font-light">
                <strong style={{ color: "rgb(245, 97, 156)" }}>
                  <a
                    href="https://starchild.com.br/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    starchild tech
                  </a>
                </strong>{" "}
                {new Date().getFullYear()} © Todos os direitos reservados.
              </span>
            </Col>
            <Col xs={12} md={2}>
              <Row>
                <Col className="d-flex justify-content-end">
                  <div className="social-media">
                    <a
                      href="https://starchild.com.br/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faGlobe} />
                    </a>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end">
                  <div className="social-media">
                    <a
                      href="https://www.instagram.com/starchild.tech/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
