import React, { createContext, useContext, useState, useEffect } from "react";
import authService from "../services/authService";
import clinicService from "../services/clinicService";
import Loading from "../components/Loading";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(() => {
    const savedClinic = localStorage.getItem("selectedClinic");
    return savedClinic ? JSON.parse(savedClinic) : null;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const userInfo = await authService.getUserInfo();
        setUser(userInfo.user);
        setInitialClinic(userInfo.user);
      } catch (error) {
        console.error(error);
        setError(error);
        localStorage.clear();
        setUser(null);
        setSelectedClinic(null);
      } finally {
        setIsLoading(false);
      }
    };

    const setInitialClinic = async (user) => {
      setIsLoading(true);
      if (user && !selectedClinic) {
        if (
          user.role !== "admin" &&
          user.clinics &&
          user.clinics.length > 0 &&
          !selectedClinic
        ) {
          await clinicService.selectClinic(user.clinics[0]._id);
          updateSelectedClinic(user.clinics[0]);
        } else if (
          user.role === "admin" &&
          user.clinics &&
          user.clinics.length > 0
        ) {
          updateSelectedClinic(user.clinics[0]);
        } else {
          updateSelectedClinic(null);
        }
      }
      setIsLoading(false);
    };

    loadUser();
  }, [selectedClinic]);

  const updateSelectedClinic = (clinic) => {
    setIsLoading(true);
    if (clinic) {
      setSelectedClinic(clinic);
      if (clinic) {
        localStorage.setItem("selectedClinic", JSON.stringify(clinic));
      } else {
        localStorage.removeItem("selectedClinic");
      }
    }
    setIsLoading(false);
  };

  const changeSelectedClinic = (clinicId) => {
    setIsLoading(true);
    const clinic = user.clinics.find((c) => c._id === clinicId);
    if (clinic) {
      setSelectedClinic(clinic);
      localStorage.setItem(
        "selectedClinic",
        JSON.stringify({
          fullName: clinic.fullName,
          legalName: clinic.legalName,
          logoUrl: clinic.logoUrl,
          _id: clinic._id,
        })
      );

      clinicService.selectClinic(clinicId).then(() => {
        window.location.replace("/dashboard");
      });
    }
    setIsLoading(false);
  };

  const changeSelectedClinicAdmin = (item) => {
    setIsLoading(true);
    if (item && item._id) {
      setSelectedClinic({
        fullName: item.fullName,
        legalName: item.legalName,
        logoUrl: item.logoUrl,
        _id: item._id,
      });

      localStorage.setItem(
        "selectedClinic",
        JSON.stringify({
          fullName: item.fullName,
          legalName: item.legalName,
          logoUrl: item.logoUrl,
          _id: item._id,
        })
      );

      clinicService.selectClinic(item._id).then(() => {
        window.location.replace("/dashboard");
      });
    } else {
      setSelectedClinic(null);
      localStorage.removeItem("selectedClinic");
      clinicService.selectClinic(null).then(() => {
        window.location.replace("/dashboard");
      });
    }
    setIsLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        selectedClinic,
        changeSelectedClinic,
        changeSelectedClinicAdmin,
        isLoading,
        error,
      }}
    >
      {!isLoading ? children : <Loading show={isLoading} />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
