import api from "../config/api.js";

const exportFileClinicAppointments = async (data, name) => {
  const response = await api.get(
    `/export-files/clinic-appointments?patient=${data.patient}&startDate=${data.startDate}&endDate=${data.endDate}&type=${data.type}`,
    {
      responseType: "blob",
    }
  );

  const fileName = response.headers["content-disposition"]
    ? response.headers["content-disposition"].split("filename=")[1]
    : `${name}.${data.type}`;

  const blob = new Blob([response.data], { type: response.data.type });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();

  window.URL.revokeObjectURL(link.href);
};

const apiMethods = {
  exportFileClinicAppointments,
};
export default apiMethods;
