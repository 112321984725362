import React from "react";
import { Button } from "react-bootstrap";

function ButtonHeader({ btnText, onClick }) {
  return (
    <Button className="custom-btn" onClick={onClick}>
      <span>{btnText}</span>
    </Button>
  );
}

function ButtonPrimary({ btnText, type, className, disabled }) {
  return (
    <Button type={type} className={className} disabled={disabled}>
      <span className="text-uppercase">{btnText}</span>
    </Button>
  );
}

function ButtonCallToACtion({
  btnText,
  className,
  type,
  onClick,
  disabled = false,
}) {
  return (
    <Button
      className={`btn-call-to-action ` + className}
      type={type ? type : "button"}
      onClick={onClick}
      disabled={disabled}
    >
      <span className="text-uppercase">{btnText}</span>
    </Button>
  );
}

function ButtonFooter({ btnText, onClick }) {
  return (
    <Button className="custom-btn-primary align-self-center" onClick={onClick}>
      <span>{btnText}</span>
    </Button>
  );
}

function ButtomCustom({ btnText, onClick }) {
  return (
    <Button className="custom-btn" onClick={onClick}>
      <span className="text-uppercase">{btnText}</span>
    </Button>
  );
}

export {
  ButtonHeader,
  ButtonPrimary,
  ButtonCallToACtion,
  ButtonFooter,
  ButtomCustom,
};
