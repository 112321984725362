import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../../components/Sidebar";
import { useAuth } from "../../../context/authContext";
import { getFirstName } from "../../../config/utils";

function Page404() {
  const { user } = useAuth();
  useEffect(() => {
    document.title = "We Care Sync - 404";
  }, []);

  return (
    <>
      <Sidebar pageName="404" pageUrl="/dashboard/404">
        <Container fluid>
          <Row>
            <Col xs={12} md={12}>
              <h5>
                Prazer em te ver novamente,{" "}
                <strong>{getFirstName(user.fullName)}</strong>!
              </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h5>
                Infelizmente não fora possível encontrar essa página{" "}
                <strong className="color-primary">:(</strong>.
              </h5>
            </Col>
          </Row>
        </Container>
      </Sidebar>
    </>
  );
}

export default Page404;
