import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useAuth } from "../../context/authContext";

const FilterBar = ({
  config,
  filters,
  onFilterChange,
  onAddClick,
  onAddText,
}) => {
  const { user } = useAuth();

  return (
    <>
      {onAddClick &&
        (user?.role === "admin" || user?.clinicRole !== "user") && (
          <Row className="m-2 justify-content-end">
            <Col xs={12} md={4} className="d-flex justify-content-end mb-2">
              <Button
                className="custom-btn btn-app btt-add d-flex align-items-center justify-content-center"
                onClick={onAddClick}
              >
                <span className="material-icons p-0 me-2" style={{ fontSize: 20 }}>add_circle_outline</span>
                {onAddText || "Adicionar"}
              </Button>
            </Col>
          </Row>
        )}
      <Row className="m-2 justify-content-end">
        {config.map((item, index) => (
          <Col key={index} xs={12} sm={item.size} className="text-right">
            {item.label && <small>{item.label}</small>}
            {item.type === "text" && (
              <input
                type="text"
                className="form-control input-search"
                name={item.name}
                value={filters[item.name]}
                onChange={onFilterChange}
              />
            )}
            {item.type === "select" && (
              <select
                className="form-control input-search"
                name={item.name}
                value={filters[item.name]}
                onChange={onFilterChange}
              >
                {item.options.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}
            {item.type === "date" && (
              <input
                type="date"
                className="form-control input-search"
                name={item.name}
                value={filters[item.name]}
                onChange={onFilterChange}
              />
            )}
          </Col>
        ))}
      </Row>
    </>
  );
};

export default FilterBar;
