import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const AppointmentsForm = ({
  professionals = [],
  patients = [],
  schedules = [],
  appointment,
  handleInputChange,
}) => {
  const dayTranslations = {
    monday: "Segunda-feira",
    tuesday: "Terça-feira",
    wednesday: "Quarta-feira",
    thursday: "Quinta-feira",
    friday: "Sexta-feira",
    saturday: "Sábado",
    sunday: "Domingo",
  };

  return (
    <>
      <Row className="mb-0 mb-md-3">
        <Col xs={12} md={appointment._id ? 6 : 4} className="mb-3 mb-md-2">
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Paciente
              {!appointment._id && (
                <sup className="ms-1 text-danger fw-bold">*</sup>
              )}
            </Form.Label>
            <Form.Control
              as="select"
              name="patient"
              defaultValue={
                appointment._id
                  ? appointment.patient._id
                  : appointment.patient || ""
              }
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Selecione o Paciente
              </option>
              {patients.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.fullName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} md={appointment._id ? 6 : 4} className="mb-3 mb-md-2">
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Profissional
              {!appointment._id && (
                <sup className="ms-1 text-danger fw-bold">*</sup>
              )}
            </Form.Label>
            <Form.Control
              as="select"
              name="user"
              defaultValue={
                appointment._id ? appointment.user._id : appointment.user || ""
              }
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Selecione o Profissional
              </option>
              {professionals.map((item, index) => (
                <option value={item._id} key={index}>
                  {`${item.user?.fullName}, ${item.profession}`}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} md={appointment._id ? 6 : 4} className="mb-3 mb-md-2">
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Agenda
              {!appointment._id && (
                <sup className="ms-1 text-danger fw-bold">*</sup>
              )}
            </Form.Label>
            <Form.Control
              as="select"
              name="schedule"
              defaultValue={
                appointment._id
                  ? appointment.schedule._id
                  : appointment.schedule || ""
              }
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Selecione a Agenda
              </option>
              {schedules.map((item, index) => (
                <option value={item._id} key={index}>
                  {`${dayTranslations[item.day]}, ${item.startTime} - ${
                    item.endTime
                  }`}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        {appointment._id && (
          <>
            <Col xs={12} md={appointment._id ? 6 : 4} className="mb-3 mb-md-2">
              <Form.Group className="mb-3 mb-md-2">
                <Form.Label className="text-uppercase">Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  defaultValue={appointment.status || ""}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Selecione o Status
                  </option>
                  {[
                    { label: "Pendente", value: "pending" },
                    { label: "Confirmado", value: "confirmed" },
                    { label: "Cancelado sem Justificativa", value: "rejected" },
                    { label: "Cancelado com Justificativa", value: "canceled" },
                  ].map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            {appointment.status === "canceled" && (
              <Col xs={12} md={12} className="mb-3 mb-md-2">
                <Form.Group className="mb-3 mb-md-2">
                  <Form.Label className="text-uppercase">
                    Justificativa
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="notes"
                    defaultValue={appointment.notes || ""}
                    onChange={handleInputChange}
                    required={
                      appointment.status === "canceled" ||
                      appointment.status === "canceled"
                    }
                    rows={5}
                  />
                </Form.Group>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default AppointmentsForm;
