import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import FormLogin from "../../components/Forms/Login";
import Loading from "../../components/Loading";

function Login() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "We Care Sync - Login";

    const checkAuth = async () => {
      setIsLoading(true);
      if (localStorage.getItem("isAuthenticated") === "true") {
        window.location.href = "/dashboard";
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  return (
    <div className="bg-color-primary">
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-4KWR5LNY2P"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-4KWR5LNY2P');
          `}
        </script>
      </Helmet>
      <Row className="min-vh-100 m-0">
        <Col md={6} className="p-0 d-none d-md-flex bg-login"></Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <FormLogin setIsLoading={setIsLoading} />
        </Col>
      </Row>
      <Loading show={isLoading} />
    </div>
  );
}

export default Login;
