import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Loading from "../../components/Loading";
import FormPasswordReset from "../../components/Forms/PasswordReset";

function PasswordReset() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "We Care Sync - Alteração de Senha";
    if (localStorage.getItem("isAuthenticated")) {
      window.location.href = "/dashboard";
    }
  }, []);

  return (
    <section className="section-height-100 center-items bg-color-primary">
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-4KWR5LNY2P"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-4KWR5LNY2P');
          `}
        </script>
      </Helmet>
      <Container>
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <FormPasswordReset setLoading={setLoading} />
          </Col>
        </Row>
      </Container>
      <Loading show={loading} />
    </section>
  );
}

export default PasswordReset;
