import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
  translateScheduleDays,
  translateAppointmentStatus,
} from "../../../../../../config/utils";

const AppointmentsSingleForm = ({ appointment, handleInputChange }) => {
  return (
    <>
      <Row>
        <Col xs={12} sm={3} className="mb-3">
          <h6 className="fw-bold">Paciente</h6>
          <p className="text-muted">{appointment.patient?.fullName}</p>
        </Col>
        <Col xs={12} sm={3} className="mb-3">
          <h6 className="fw-bold">Profissional</h6>
          <p className="text-muted">
            {appointment.user?.user?.fullName}, {appointment.user?.profession}
          </p>
        </Col>
        <Col xs={12} sm={3} className="mb-3">
          <h6 className="fw-bold">Agenda</h6>
          <p className="text-muted">
            {translateScheduleDays(appointment.schedule?.day)} de{" "}
            {appointment.schedule?.startTime} até{" "}
            {appointment.schedule?.endTime}
          </p>
        </Col>
        <Col xs={12} sm={3} className="mb-3">
          <h6 className="fw-bold">Status</h6>
          <p
            className={`fw-bold text-${translateAppointmentStatus(
              "color",
              appointment.status
            )}`}
          >
            {translateAppointmentStatus("label", appointment.status)}
          </p>
        </Col>
        <Col xs={12} md={12} className="mb-3 mb-md-2">
          <Form.Group className="mb-3 mb-md-2">
            <Form.Label className="text-uppercase">
              Descrição do Atendimento
            </Form.Label>
            <Form.Control
              as="textarea"
              name="notes"
              defaultValue={appointment.notes || ""}
              onChange={handleInputChange}
              required
              rows={5}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default AppointmentsSingleForm;
