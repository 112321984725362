import React, { Component } from "react";
import { Col, Card, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { ButtonCallToACtion } from "../Buttons";

export class CardList extends Component {
  render() {
    let { page = 0, pages = 0, countItems = 0, data } = this.props;
    if (data) {
      countItems = data.length || 0;
    }

    return (
      <Card className="border-0 mb-2" style={{ background: "transparent" }}>
        <Card.Body>{this.props.children}</Card.Body>
        {page > 0 ? (
          <Card.Footer
            className="border-0 d-flex align-items-center justify-content-between"
            style={{ background: "transparent" }}
          >
            <p className="text-muted m-0 small">
              Página {page} de {pages}. Mostrando{" "}
              {countItems !== 1 ? `${countItems} itens` : `${countItems} item`}.
            </p>
            <ButtonGroup aria-label="Pagination">
              <Button
                className={"btn-pagination"}
                style={page === 1 ? { cursor: "not-allowed" } : {}}
                disabled={page === 1}
                onClick={(event) => this.props.callbackPrev(event)}
              >
                Anterior
              </Button>
              <Button
                className={"btn-pagination"}
                style={page === pages ? { cursor: "not-allowed" } : {}}
                disabled={page === pages}
                onClick={(event) => this.props.callbackNext(event)}
              >
                Próxima
              </Button>
            </ButtonGroup>
          </Card.Footer>
        ) : (
          <></>
        )}
      </Card>
    );
  }
}

export class CardListHeader extends Component {
  render() {
    return (
      <Card
        className={
          "d-none d-lg-flex border-bottom border-top-0 border-end-0 border-start-0 rounded mb-1 " +
          this.props.className
        }
      >
        <Card.Body className="p-3">{this.props.children}</Card.Body>
      </Card>
    );
  }
}

export class CardListHeaderItem extends Component {
  render() {
    const {
      xs = "12",
      sm = "12",
      md = "12",
      lg = "12",
      className = "",
    } = this.props;

    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={
          className +
          " color-dark-gray text-uppercase font-semi-bold text-break d-flex align-items-center"
        }
      >
        {this.props.children}
      </Col>
    );
  }
}

export class CardListHeaderSortItem extends Component {
  render() {
    const {
      xs = "12",
      sm = "12",
      md = "12",
      lg = "12",
      className = "",
      onSort,
      sortKey,
      children,
    } = this.props;

    const handleClick = () => {
      if (onSort && sortKey) {
        onSort(sortKey);
      }
    };

    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={
          className +
          " d-flex align-items-center card-list-header-sort color-dark-gray text-uppercase font-semi-bold text-break"
        }
      >
        {children}
        {onSort && (
          <span className="material-icons c-pointer" onClick={handleClick}>
            swap_vert
          </span>
        )}
      </Col>
    );
  }
}

export class CardListBody extends Component {
  render() {
    return (
      <Card
        className={`rounded-0 mb-1 card-data ${this.props.className} text-break`}
      >
        <Card.Body className="p-3">{this.props.children}</Card.Body>
      </Card>
    );
  }
}

export class CardListBodyItem extends Component {
  truncateHtml = (html, maxLength) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    let truncatedHtml = "";
    let charCount = 0;
    let brCount = 0;

    const traverseNodes = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const remainingChars = maxLength - charCount;
        if (charCount + node.textContent.length <= maxLength) {
          truncatedHtml += node.textContent;
          charCount += node.textContent.length;
          brCount = 0;
        } else {
          truncatedHtml += node.textContent.substring(0, remainingChars);
          charCount = maxLength;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName.toLowerCase() === "br") {
          if (brCount === 0) {
            truncatedHtml += "<br />";
          }
          brCount++;
        } else if (
          node.tagName.toLowerCase() === "p" &&
          Array.from(node.childNodes).every(
            (childNode) => childNode.nodeName.toLowerCase() === "br"
          )
        ) {
          brCount++; 
        } else {
          truncatedHtml += `<${node.tagName.toLowerCase()}${Array.from(
            node.attributes
          )
            .map((attr) => ` ${attr.name}="${attr.value}"`)
            .join("")}>`;

          brCount = 0;

          node.childNodes.forEach((childNode) => {
            if (charCount < maxLength) {
              traverseNodes(childNode);
            }
          });

          truncatedHtml += `</${node.tagName.toLowerCase()}>`;
        }
      }
    };

    doc.body.childNodes.forEach((node) => {
      if (charCount < maxLength) {
        traverseNodes(node);
      }
    });

    truncatedHtml = truncatedHtml.replace(/(<br\s*\/?>\s*){2,}/g, "<br />");

    return truncatedHtml;
  };

  render() {
    const {
      xs = "12",
      sm = "12",
      md = "12",
      lg = "12",
      className = "",
      value = "",
      style = {},
      isHtml = false,
      maxLength = 100,
    } = this.props;

    const displayValue = isHtml ? this.truncateHtml(value, maxLength) : value;

    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={`${className} text-break ${isHtml && "item-html"}`}
        style={style}
      >
        {isHtml ? (
          <span dangerouslySetInnerHTML={{ __html: displayValue }} />
        ) : (
          displayValue
        )}
      </Col>
    );
  }
}

export class CardListBodyButton extends Component {
  render() {
    const {
      xs = "12",
      sm = "12",
      md = "12",
      lg = "12",
      className = "",
      title = "",
      style = {},
      callToAction,
    } = this.props;

    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={`${className} `}
        style={style}
      >
        <ButtonCallToACtion btnText={title} onClick={callToAction} />
      </Col>
    );
  }
}

export class CardListBodyItemOptions extends Component {
  render() {
    const {
      xs = "12",
      sm = "12",
      md = "12",
      lg = "12",
      className = "",
    } = this.props;

    return (
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        className={`${className} text-break`}
      >
        <Dropdown drop={"left"}>
          <Dropdown.Toggle
            variant="muted"
            id="dropdown-basic"
            className="p-0 text-muted"
            style={{ lineHeight: 0 }}
          >
            <span className="material-icons">settings</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>{this.props.children}</Dropdown.Menu>
        </Dropdown>
      </Col>
    );
  }
}
