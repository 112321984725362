import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/authContext";

function PrivateRoute({
  children,
  adminOnly = false,
  requireClinic = false,
  onlyUser = false,
  clinicAdminOnly = false,
  requireOwner = false,
}) {
  const { user, selectedClinic } = useAuth();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (adminOnly && user.role !== "admin") {
    return <Navigate to="/dashboard" replace />;
  }

  if (requireClinic && !selectedClinic && user.role === "admin") {
    return <Navigate to="/dashboard-admin" replace />;
  }

  if (
    clinicAdminOnly &&
    !(
      user?.clinicRole === "admin" ||
      user?.clinicRole === "owner" ||
      user.role === "admin"
    )
  ) {
    return <Navigate to="/atendimentos" replace />;
  }

  if (
    requireOwner &&
    !(user?.clinicRole === "owner" || user.role === "admin")
  ) {
    return <Navigate to="/dashboard" replace />;
  }

  if (onlyUser && requireClinic && user.role !== "user") {
    return <Navigate to="/dashboard" replace />;
  }

  if (user.role === "user" && requireClinic && !user.clinicRole) {
    return <Navigate to="/dashboard/404" replace />;
  }

  return children;
}

export default PrivateRoute;
