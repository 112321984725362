import api from "../config/api.js";

const fetchData = async ({
  paginate = true,
  page,
  sortBy,
  order,
  limit,
  search,
  status,
}) => {
  const response = await api.get(
    `/clinic-roles?paginate=${paginate}&page=${page}&sortBy=${sortBy}&order=${order}&limit=${limit}&search=${search}&status=${status}`
  );
  return response.data;
};

const fetchInvites = async () => {
  const statuses = ["pending", "rejected", "deleted"];
  const statusQuery = statuses
    .map((status) => `status[]=${encodeURIComponent(status)}`)
    .join("&");
  const url = `/users-invitations?${statusQuery}`;
  const response = await api.get(url);
  return response.data;
};

const submitInvite = async (type, data) => {
  if (type === "add") {
    try {
      const response = await api.post("/users-invitations", data);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  } else {
    try {
      const response = await api.put(`/users-invitations/${data._id}`, data);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
      throw error.response.data;
    }
  }
};

const updateRole = async (data) => {
  try {
    const response = await api.put(`/clinic-roles/${data._id}`, data);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const resendInvitation = async (id) => {
  try {
    const response = await api.post(`/users-invitations/${id}/resend`);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const deleteInvite = async (id) => {
  try {
    const response = await api.delete(`/users-invitations/${id}`);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const inactiveRole = async (id) => {
  try {
    const response = await api.delete(`/clinic-roles/${id}`);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const attachPatient = async (id, data) => {
  try {
    const response = await api.put(`/clinic-roles/${id}/attach`, data);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const getProfessionals = async () => {
  try {
    const response = await api.get(
      "/clinic-roles/select"
    );
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    throw error.response.data;
  }
};

const apiMethods = {
  fetchData,
  fetchInvites,
  submitInvite,
  updateRole,
  resendInvitation,
  deleteInvite,
  inactiveRole,
  attachPatient,
  getProfessionals,
};
export default apiMethods;
